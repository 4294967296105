<template>
  <div class="footer">
    <div class="section10-bg">
      <div class="section10">
        <div class="section10-img-box">
          <div class="section10-img-box-left" @click="toAboutUs">
            <!-- <span>What else do you want to know</span>
            <img src="@/assets/home/section10-more.png" alt="" /> -->
            <span>了解更多...</span>
          </div>
          <div class="section10-img-box-right">
            <div class="section10-img-box-right-box">
              <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section10-img2.jpeg" alt="" />
              <div>小糖管家-患者端</div>
            </div>
            <div
              style="margin-left: 0.10416666rem"
              class="section10-img-box-right-box"
            >
              <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section10-img3.jpeg" alt="" />
              <div>小糖医管-医生端</div>
            </div>
            <div
              style="margin-left: 0.10416666rem"
              class="section10-img-box-right-box"
            >
              <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section10-img4.jpeg" alt="" />
              <div>1CARE健康公众号</div>
            </div>
          </div>
        </div>
        <div class="section10-logo">
          <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section10-logo.png" alt="" />
        </div>
        <div class="section10-box">
          <el-row>
            <el-col
              :span="12"
              style="font-family: 'CN-Light'; padding: 0 0.05208333rem"
            >
              <div class="section10-box-item">
                <div class="section10-box-item-img">
                  <img
                    style="width: 0.078125rem; height: 0.078125rem"
                    src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section10-phone.png"
                    alt=""
                  />
                </div>
                <div class="section10-box-item-label">联系方式</div>
                <span class="section10-box-item-split">:</span>
                <span>400-100-</span>
              </div>
              <div class="section10-box-item">
                <div class="section10-box-item-img">
                  <img
                    style="width: 0.08333333rem; height: 0.0625rem"
                    src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section10-msg.png"
                    alt=""
                  />
                </div>
                <div class="section10-box-item-label">邮 箱</div>
                <span class="section10-box-item-split">:</span>
                <span>service@lian-lai.com</span>
              </div>
              <div class="section10-box-item">
                <div class="section10-box-item-img">
                  <img
                    style="width: 0.05729166rem; height: 0.078125rem"
                    src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section10-position.png"
                    alt=""
                  />
                </div>
                <div class="section10-box-item-label">地址</div>
                <span class="section10-box-item-split">:</span>
                <span>上海市虹口区海宁路358号主楼15层B座01室</span>
              </div>
            </el-col>
            <el-col
              :span="12"
              style="font-family: 'CN-Light'; padding: 0 0.05208333rem"
            >
              <div class="section10-box-item">
                <div class="section10-box-item-label">单位名称</div>
                <span class="section10-box-item-split">:</span>
                <span>上海联莱信息科技有限公司</span>
              </div>
              <div class="section10-box-item">
                <div class="section10-box-item-label">版 权</div>
                <span class="section10-box-item-split">:</span>
                <span
                  >Copyright @ 2022 1CARE 1CARE健康. All rights reserved.</span
                >
              </div>
              <div class="section10-box-item">
                <div class="section10-box-item-label">备 案 号</div>
                <span class="section10-box-item-split">:</span>
                <span class="aaaaaa">
                  <span class="beian">
                    <img src="../../../assets/home/beian.jpg" alt="">
                    <a href="" rel="noreferrer" target="_blank">沪公网安备31010902100672</a>
                    &nbsp;&nbsp;
                  </span>
                  沪ICP备20012354号-3  &nbsp;&nbsp;  (沪)-经营性-2023-0252
                </span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  methods: {
    toAboutUs() {
      this.$router.push({ path: "/aboutUs", query: { target: "target" } });
    },
  },
};
</script>
<style lang="less" scoped>
.footer {
  width: 100%;
  .section10-bg {
    height: 580px;
    background: #233972;
    box-sizing: border-box;
    padding-top: 109px;
    .section10 {
      width: 1200px;
      margin: 0 auto;
      &-img-box {
        display: flex;
        justify-content: space-between;
        &-left {
          font-size: 22px;
          font-family: "CN-Medium";
          color: white;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section10-img1.png") no-repeat;
          background-size: 100% 100%;
          width: 627px;
          height: 96px;
          padding: 0 35px;
          cursor: pointer;
          img {
            cursor: pointer;
            width: 120px;
            height: 40px;
          }
          img:hover {
            transform: scale(1.02);
            transition: all 0.5s;
          }
        }
        &-right {
          display: flex;
          &-box {
            text-align: center;
            font-size: 13px;
            font-family: "CN-Normal";
            color: white;
            margin-top: 10px;
            img {
              width: 111px;
              height: 110px;
            }
          }
        }
      }
      &-logo {
        img {
          width: 122px;
          height: 41px;
        }
      }
      &-box {
        margin-top: 20px;
        font-size: 18px;
        color: white;
        &-item {
          display: flex;
          // align-items: center;
          margin-bottom: 10px;
          &-img {
            width: 15px;
            height: 15px;
            margin-right: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              margin-top: 10px;
            }
          }
          &-label {
            font-family: "CN-Medium";
            font-weight: 500;
            width: 80px;
            text-align: justify;
            text-justify: distribute-all-lines;
            text-align-last: justify;
          }
          &-split {
            padding: 0 10px;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.beian {
  img {
    width: 16px;
    margin-right: 5px;
  }

  a {
    color: #fff;
    text-decoration: none;
  }
}
.aaaaaa{
  width: 450px;
}
</style>
